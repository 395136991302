import info from "../package.json";

export const WEB_VERSION = info.version;

export const PRODUCTION = process.env.REACT_APP_PRODUCTION === 'true';
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const PAGE_URL = process.env.REACT_APP_PAGE_URL;
export const PAYPAL_URL = process.env.REACT_APP_PAYPAL_URL;
export const clientId = process.env.REACT_APP_CLIENT_ID;
export const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
export const apiVersion = process.env.REACT_APP_API_VERSION;
export const storageKey = String(process.env.REACT_APP_STORAGE_KEY);
export const highRes = {
  width: process.env.REACT_APP_HIGH_RES_WIDTH
    ? parseInt(process.env.REACT_APP_HIGH_RES_WIDTH)
    : 2000,
  height: process.env.REACT_APP_HIGH_RES_HEIGHT
    ? parseInt(process.env.REACT_APP_HIGH_RES_HEIGHT)
    : 2000
};
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
export const FACEBOOK_PIXEL = {
  name: process.env.REACT_APP_FACEBOOK_PIXEL_NAME,
  id: String(process.env.REACT_APP_FACEBOOK_PIXEL_ID)
};
export const AMPLITUDE = {
  apiKey: String(process.env.REACT_APP_AMPLITUDE_API_KEY)
};
export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};
export const PAYPAL = {
  apiKey: process.env.REACT_APP_PAYPAL_API_KEY,
  merchantId: process.env.REACT_APP_PAYPAL_MERCHANT_ID,
  mode: process.env.REACT_APP_PAYPAL_MODE,
  commit: process.env.REACT_APP_PAYPAL_COMMIT === 'true',
  locale: process.env.REACT_APP_PAYPAL_LOCALE,
  style: {
    label: process.env.REACT_APP_PAYPAL_STYLE_LABEL,
    size: process.env.REACT_APP_PAYPAL_STYLE_SIZE,
    shape: process.env.REACT_APP_PAYPAL_STYLE_SHAPE,
    color: process.env.REACT_APP_PAYPAL_STYLE_COLOR,
    tagline: process.env.REACT_APP_PAYPAL_STYLE_TAGLINE === 'true'
  },
  buttons: {
    monthly: String(process.env.REACT_APP_PAYPAL_BUTTONS_MONTHLY),
    three_month: String(process.env.REACT_APP_PAYPAL_BUTTONS_THREE_MONTH),
    one_year: String(process.env.REACT_APP_PAYPAL_BUTTONS_ONE_YEAR)
  }
};
export const highResContent = {
  width: process.env.REACT_APP_HIGH_RES_CONTENT_WIDTH
    ? parseInt(process.env.REACT_APP_HIGH_RES_CONTENT_WIDTH)
    : 2000,
  height: process.env.REACT_APP_HIGH_RES_CONTENT_HEIGHT
    ? parseInt(process.env.REACT_APP_HIGH_RES_CONTENT_HEIGHT)
    : 2000
};
